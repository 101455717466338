body.auth-layout {
	background:			url('../admin/images/auth/background.jpg') no-repeat center center fixed;
	background-size:	cover;
	
	#content {
		height:		100%;
		background:	rgba($black, 0.2);
	}
	
	.card {
		margin-top:	150px;
		position:	relative;
		
		.card-logo {
			text-align:	center;
			left:		0;
			margin:		auto;
			position:	absolute;
			right:		0;
			top:		-110px;
			
			img {
				height:	90px;
			}
		}
		
		.card-heading {
			background:	$purple;
			color:		$white;
			
			line-height:	20px;
			min-height:		20px;
			padding:		10px;
		}
		
		.card-body {
			padding:	50px 30px 30px 50px;
			
			p {
				margin-bottom:	0;
			}
			
			h2 {
				margin-top:	0;
			}
		}
		
		.buttons {
			text-align:		right;
			margin-top:		10px;
			
			.btn {
				text-transform:	uppercase;
			}
		}
			
		@include respond-to('small') {
			@include vertical-align();
			width:		550px;
			position:	absolute;
			left:		0;
			right:		0;
			margin:		auto;
		}
			
		@include respond-to('medium') {
			width:		600px;
			max-width:	100%;
		}
	}
	
	.control-label {
		text-align:		left;
		font-weight:	normal;
		opacity:		1;
		color:			$french_gray;
		font-size:		1em;
	}
	
	.functions {
		padding-top:	25px;
		
		.remember {
			padding-left:	3em;
		}
		
		.forget {
			padding-top:	4px;
			text-align:		center;
			padding-right:	2em;
		}
		
		a {
			color:			$french_gray;
			font-weight:	$regular;
		}
	}
	
	label.checkbox {
		padding:		0;
		font-weight:	normal;
		color:			$french_gray;
		
		.checkbox-material {
			margin-right:	1em;
		}
	}

	@include respond-to('small') {
		.col-sm-3 {
			width:	33.3333%;
			float:	left;
		}
		
		.col-sm-6 {
			width:	50%;
			float:	left;
		}

		.col-sm-9 {
			width:	66.6667%;
			float:	left;
		}

		.col-sm-12 {
			width:	100%;
			float:	left;
		}
	}
}
		
.btn-a {
	
	&:hover {
		color:				$white;
		text-decoration:	none;
	}
}