$white:               #ffffff;
$alabaster:           #f9f9f9;
$athens_gray:         #f3f4f6;
$whisper:             #f5f6fa;
$gallery:             #eeeeee;
$athens_gray_five:    #eaeaf1;
$mischka:             #dddde3;
$mystic:              #e0e4eb;
$ghost_two:           #cacad8;
$gray_suit:           #c1c1d1;
$ghost_three:         #c9c9d1;
$ghost:               #c4c7cf;
$ghost_dark:          #c5c8d0;
$ghost_four:          #c4c8cf;
$silver_two:          #bbbbbb;
$french_gray:         #555555;
$bombay:              #b8b9be;
$silver:              #c9c5c4;
$swirl:               #d4cdc7;
$schooner:            #958a87;
$scarpa_flow:         #5c5c5f;
$dove_gray:           #666666;
$shark:               #252527;
$mine_shaft:          #313131;
$black:               #000000;
$mint_tulip:          #bce8f1;
$fountain_blue:       #64bbc4;
$eastern_blue:        #189492;
$hippie_blue:         #648faf;
$hippie_blue_two:     #649fb7;
$twitter:             #55acee;
$steel_blue:          #337ab7;
$facebook:            #475993;
$purple:		      #9283be;
$purple_dark:	      #4a2683;
$vivid_violet:        #832c8f;
$copper:              #bf7f3f;
$google:              #dd4b39;
