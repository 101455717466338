.trumbowyg-editor {
  h1 {
    margin-top: 10px;
    font-size: 2em;
    margin-bottom: 20px;
    font-weight: 700;
  }
  .text {
    margin-bottom: 20px;
    line-height: 1.63;
    font-weight: 300;
    strong {
      font-weight: 700;
    }
    ol {
      margin-top: 0;
      margin-bottom: 10px;
      h3 {
        font-size: 24px;
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
      }
    }
  }
}