$breakpoints: (
        'xxsmall': (min-width: 400px),
        'xsmall': (min-width: 600px),
        'small': (min-width: 768px),
        'medium': (min-width: 992px),
        'xmedium': (min-width: 1130px),
        'large': (min-width: 1200px),
        'xlarge': (min-width: 1366px),
        'xxlarge': (min-width: 1600px),
        'fullhd': (min-width: 1800px),
        'h800': '(min-width: 1366px) and (min-height: 800px)'
);

/// Menadżer responsywności.
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(type-of($raw-query) == 'string', unquote($raw-query), inspect($raw-query));

    @media #{$query} {
      @content;
    }
  } @else {
    @if (type-of($breakpoint) == 'number') {
      @media (min-width: #{$breakpoint}px) {
        @content;
      }
    } @else {
      @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
    }
  }
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

/// Helper do oczyszczania floatów
/// @author Nicolas Gallagher
/// @link http://nicolasgallagher.com/micro-clearfix-hack/ Micro Clearfix
@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}