.draggable .drag {
	cursor: move;
}

.draggable.sortable-ghost {
	background: $fountain_blue !important;
}

strong.special {
    color: #189492;
    border-radius: 4px;
    background-color: #f5f6fa;
    padding: 0 5px;
    white-space: pre-wrap;
}

.trumbowyg-editor ul {
    padding-left: 15px;
}

.trumbowyg-editor ul li {
    list-style-type: disc;
}

.trumbowyg-editor cta-link {
    background: #648faf;
    color: white;
    border-radius: 20px;
    padding: 5px 10px;
}

::-webkit-input-placeholder {
  color: #666; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #666; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #666; }

:-ms-input-placeholder {
  color: #666; }